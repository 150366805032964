export const farmingV2Abi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: '_factory',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_WETH',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'bool',
                name: 'isBlueCheck',
                type: 'bool',
            },
        ],
        name: 'BlueCheck',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amountHarvested',
                type: 'uint256',
            },
        ],
        name: 'Harvest',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'pairAddress',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'tokenA',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'tokenB',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amountReward',
                type: 'uint256',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'tokenReward',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'startDate',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'endDate',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amountPerSecond',
                type: 'uint256',
            },
        ],
        name: 'PoolCreated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'accAmountPerShare',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'totalLP',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'totalReward',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'lastRewardTimestamp',
                type: 'uint256',
            },
        ],
        name: 'PoolUpdate',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'Stake',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
        ],
        name: 'StopPool',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'Unstake',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'rewardDebt',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'rewardEarn',
                type: 'uint256',
            },
        ],
        name: 'UserUpdate',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'WithdrawExcess',
        type: 'event',
    },
    {
        inputs: [],
        name: 'WETH',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'tokenA',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'tokenB',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amountReward',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'tokenReward',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'startDate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'endDate',
                type: 'uint256',
            },
        ],
        name: 'createPool',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'currentPoolId',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'factory',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
        ],
        name: 'harvest',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'poolInfos',
        outputs: [
            {
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'pairAddress',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amountReward',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'tokenReward',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'startDate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'endDate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'realEndDate',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'poolRewards',
        outputs: [
            {
                internalType: 'uint256',
                name: 'accAmountPerShare',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'totalLP',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'totalReward',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'amountPerSecond',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'lastRewardTimestamp',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'totalExcessReward',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'startTimeExcess',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                internalType: 'bool',
                name: 'isBlueCheck',
                type: 'bool',
            },
        ],
        name: 'setBlueCheck',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'amountLP',
                type: 'uint256',
            },
        ],
        name: 'stake',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
        ],
        name: 'stopPool',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'amountLP',
                type: 'uint256',
            },
        ],
        name: 'unstake',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'users',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'rewardDebt',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'rewardEarn',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'poolId',
                type: 'uint256',
            },
        ],
        name: 'withdrawExcessReward',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
]
