import { ChainId } from '@echodex/sdk'

export const LINEA_MAINNET_TOKEN_LIST =
    'https://raw.githubusercontent.com/Consensys/linea-token-list/main/json/linea-mainnet-token-shortlist.json'

// List of official tokens list
export const OFFICIAL_LISTS = [LINEA_MAINNET_TOKEN_LIST]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
    ...OFFICIAL_LISTS,
    ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
    ...WARNING_LIST_URLS,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [LINEA_MAINNET_TOKEN_LIST]

export const MULTI_CHAIN_LIST_URLS: { [chainId: number]: string[] } = {
    [ChainId.LINEA_MAINNET]: [LINEA_MAINNET_TOKEN_LIST],
}
