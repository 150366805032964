import { useTheme } from '@echodex/hooks'
import { useMatchBreakpoints } from '@echodex/uikit'
import { useTranslation } from '@echodex/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useMemo } from 'react'
import config, { ConfigMenuItemsType } from '../config/config'

export const useMenuItems = (): ConfigMenuItemsType[] => {
    const {
        t,
        currentLanguage: { code: languageCode },
    } = useTranslation()
    const { chainId } = useActiveChainId()
    const { isDark } = useTheme()
    const { isMobile } = useMatchBreakpoints()

    const menuItems = useMemo(() => {
        const mobileConfig = [...config(t, isDark, languageCode, chainId)]
        // mobileConfig.push(mobileConfig.splice(4, 1)[0])
        return isMobile ? mobileConfig : config(t, isDark, languageCode, chainId)
    }, [t, isDark, languageCode, chainId, isMobile])
    return menuItems
}
