import memoize from 'lodash/memoize'
import { ContextApi } from '@echodex/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
    title: 'EchoDEX',
    description: 'EchoDEX is the first generation DEX on LineaBuild using breakthrough technology in ZK-EVM',
    image: `/images/512x512_App_Icon.png`,
}

interface PathList {
    paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
    defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
    return {
        paths: {
            '/': { title: t('Home') },
            '/swap': { basePath: true, title: t('Exchange') },
            '/add': { basePath: true, title: t('Add Liquidity') },
            '/remove': { basePath: true, title: t('Remove Liquidity') },
            '/liquidity': { title: t('Liquidity') },
            '/find': { title: t('Import Pool') },
            '/farming': { title: t('Farms') },
            '/info': {
                title: `${t('Overview')} - ${t('Info')}`,
                description: 'View statistics for Echodex exchanges.',
            },
            '/info/pairs': {
                title: `${t('Pairs')} - ${t('Info')}`,
                description: 'View statistics for Echodex exchanges.',
            },
            '/info/tokens': {
                title: `${t('Tokens')} - ${t('Info')}`,
                description: 'View statistics for Echodex exchanges.',
            },
            '/airdrop': {
                title: `Airdrop`,
            },
            '/convert-or-redeem': {
                title: `xECP Convert and Redeem`,
            },
        },
        defaultTitleSuffix: t('EchoDEX'),
    }
}

export const getCustomMeta = memoize(
    (path: string, t: ContextApi['t'], _: string): PageMeta => {
        const pathList = getPathList(t)
        const pathMetadata =
            pathList.paths[path] ??
            pathList.paths[
                Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]
            ]

        if (pathMetadata) {
            return {
                title: `${pathMetadata.title}`,
                ...(pathMetadata.description && { description: pathMetadata.description }),
                image: pathMetadata.image,
            }
        }
        return null
    },
    (path, t, locale) => `${path}#${locale}`,
)
