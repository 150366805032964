import { ChainId } from '@echodex/sdk'
import memoize from 'lodash/memoize'
import { getMulticallAddress } from 'utils/addressHelpers'
import { lineaTestnet as lineaTestnet_, Chain } from 'wagmi/chains'

export const CHAIN_QUERY_NAME = {
    [ChainId.LINEA_MAINNET]: 'linea',
    [ChainId.LINEA_TESTNET]: 'lineaTestnet',
} as const satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
    return {
        [chainName.toLowerCase()]: chainId as unknown as ChainId,
        ...acc,
    }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
    if (!chainName) return undefined
    return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()]
        ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()]
        : undefined
})

const lineaTestnet = {
    ...lineaTestnet_,
    name: 'Linea Testnet',
    blockExplorers: {
        etherscan: {
            name: 'LineaScan',
            url: 'https://goerli.lineascan.build',
        },
        default: {
            name: 'LineaScan',
            url: 'https://goerli.lineascan.build',
        },
    },
} as const satisfies Chain

export const linea = {
    id: 59144,
    name: 'Linea Mainnet',
    network: 'linea',
    nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['https://linea-mainnet.infura.io/v3/'], webSocket: ['wss://linea-mainnet.infura.io/ws/v3/'] },
        default: { http: ['https://rpc.linea.build'], webSocket: ['wss://rpc.linea.build'] },
    },
    blockExplorers: {
        etherscan: {
            name: 'LineaScan',
            url: 'https://lineascan.build',
        },
        default: {
            name: 'LineaScan',
            url: 'https://lineascan.build',
        },
    },
    contracts: {
        multicall3: {
            address: getMulticallAddress(ChainId.LINEA_MAINNET),
        }
    }
} as const satisfies Chain

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [ChainId.LINEA_TESTNET, ChainId.LINEA_MAINNET]
export const CHAINS = [linea, lineaTestnet]