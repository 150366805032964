import { ChainId } from '@echodex/sdk'
import addresses from 'config/constants/contracts'

export interface Addresses {
    [chainId: number]: `0x${string}`
}

export const getAddressFromMap = (address: Addresses, chainId?: number): `0x${string}` => {
    return address[chainId] ? address[chainId] : address[ChainId.LINEA_TESTNET]
}

export const getAddressFromMapNoFallback = (address: Addresses, chainId?: number): `0x${string}` | null => {
    return address[chainId]
}

export const getMulticallAddress = (chainId?: number) => {
    return getAddressFromMap(addresses.multiCall, chainId)
}

export const getAirdropAddress = (chainId?: number) => {
    return getAddressFromMap(addresses.airdrop, chainId)
}

export const getXECPAddress = (chainId?: number) => {
    return getAddressFromMap(addresses.xECP, chainId)
}
export const getFarmingV2Address = (chainId?: number) => {
    return getAddressFromMap(addresses.farmingV2, chainId)
}
export const getFarmingV3Address = (chainId?: number) => {
    return getAddressFromMap(addresses.farmingV3, chainId)
}
