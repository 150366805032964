import { ChainId } from '@echodex/sdk'

export const supportedChainIdV2 = [ChainId.LINEA_TESTNET, ChainId.LINEA_MAINNET] as const
export const supportedChainIdV3 = [ChainId.LINEA_TESTNET, ChainId.LINEA_MAINNET] as const
export const bCakeSupportedChainId = [] as const

export const FARM_AUCTION_HOSTING_IN_SECONDS = 691200

export type FarmV2SupportedChainId = (typeof supportedChainIdV2)[number]

export type FarmV3SupportedChainId = (typeof supportedChainIdV3)[number]

export const masterChefAddresses = {} as const

export const masterChefV3Addresses = {
    [ChainId.LINEA_TESTNET]: '0x7959C80d824Ba4ec0F5a6194c041512e3Aa1C0a1',
    [ChainId.LINEA_MAINNET]: '0x2a284Fd69040f784C64CBd2869e3dA6F6d7f24D4',
} as const satisfies Record<FarmV3SupportedChainId, string>

export const nonBSCVaultAddresses = {} as const
