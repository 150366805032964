import {
    Box,
    Button,
    Flex,
    InjectedModalProps,
    LinkExternal,
    Message,
    Skeleton,
    Text,
    CopyAddress,
    FlexGap,
} from '@echodex/uikit'
import { WNATIVE } from '@echodex/sdk'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@echodex/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { getFullDisplayBalance, formatBigInt } from '@echodex/utils/formatBalance'
import { useBalance } from 'wagmi'

const COLORS = {
    ETH: '#627EEA',
    BNB: '#14151A',
}

interface WalletInfoProps {
    hasLowNativeBalance: boolean
    switchView: (newIndex: number) => void
    onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
    const { t } = useTranslation()
    const { account, chainId, chain } = useActiveWeb3React()
    const isBSC = false
    const nativeBalance = useBalance({ address: account, enabled: !isBSC })
    const native = useNativeCurrency()
    const wNativeToken = !isBSC ? WNATIVE[chainId] : null
    const { balance: wNativeBalance, fetchStatus: wNativeFetchStatus } = useTokenBalance(wNativeToken?.address)
    const { logout } = useAuth()

    const handleLogout = () => {
        onDismiss?.()
        logout()
    }

    return (
        <>
            <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
                {t('Your Address')}
            </Text>
            <FlexGap flexDirection="column" mb="24px" gap="8px">
                <CopyAddress tooltipMessage={t('Copied')} account={account} />
            </FlexGap>
            {hasLowNativeBalance && (
                <Message variant="warning" mb="24px">
                    <Box>
                        <Text fontWeight="bold">
                            {t('%currency% Balance Low', {
                                currency: native.symbol,
                            })}
                        </Text>
                        <Text color="primary">
                            {t('You need %currency% for transaction fees.', {
                                currency: native.symbol,
                            })}
                        </Text>
                    </Box>
                </Message>
            )}
            {!isBSC && chain && (
                <Box mb="12px">
                    <Flex justifyContent="space-between" alignItems="center" mb="8px">
                        <Flex bg={COLORS.ETH} borderRadius="16px" pl="4px" pr="8px" py="2px">
                            <ChainLogo chainId={chain.id} />
                            <Text color="white" ml="4px">
                                {chain.name}
                            </Text>
                        </Flex>
                        <LinkExternal href={getBlockExploreLink(account, 'address', chainId)}>
                            {getBlockExploreName(chainId)}
                        </LinkExternal>
                    </Flex>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text color="textSubtle">
                            {native.symbol} {t('Balance')}
                        </Text>
                        {!nativeBalance.isFetched ? (
                            <Skeleton height="22px" width="60px" />
                        ) : (
                            nativeBalance && <Text>{formatBigInt(nativeBalance?.data?.value ?? 0n, 6)}</Text>
                        )}
                    </Flex>
                    {wNativeBalance && wNativeBalance.gt(0) && (
                        <Flex alignItems="center" justifyContent="space-between">
                            <Text color="textSubtle">
                                {wNativeToken?.symbol} {t('Balance')}
                            </Text>
                            {wNativeFetchStatus !== FetchStatus.Fetched ? (
                                <Skeleton height="22px" width="60px" />
                            ) : (
                                wNativeToken?.decimals && (
                                    <Text>{getFullDisplayBalance(wNativeBalance, wNativeToken?.decimals, 6)}</Text>
                                )
                            )}
                        </Flex>
                    )}
                </Box>
            )}
            <Button variant="secondary" width="100%" minHeight={48} onClick={handleLogout}>
                {t('Disconnect Wallet')}
            </Button>
        </>
    )
}

export default WalletInfo
