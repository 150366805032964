import { ECP } from '@echodex/tokens'

// Addresses
import { getFarmingV2Address, getAirdropAddress, getXECPAddress, getFarmingV3Address } from 'utils/addressHelpers'

import { ChainId } from '@echodex/sdk'
import { lpTokenABI } from 'config/abi/lpTokenAbi'
import { viemClients } from 'utils/viem'
import { Abi, PublicClient, WalletClient, getContract as viemGetContract } from 'viem'
import { Address, erc20ABI, erc721ABI } from 'wagmi'
import { airdropAbi } from 'config/abi/airdrop'
import { xECPAbi } from 'config/abi/xECP'
import { farmingV2Abi } from 'config/abi/farmingV2'
import { farmingV3Abi } from 'config/abi/farmingV3'

export const getContract = <TAbi extends Abi | unknown[], TWalletClient extends WalletClient>({
    abi,
    address,
    chainId = ChainId.LINEA_MAINNET,
    publicClient,
    signer,
}: {
    abi: TAbi
    address: Address
    chainId?: ChainId
    signer?: TWalletClient
    publicClient?: PublicClient
}) => {
    const c = viemGetContract({
        abi,
        address,
        publicClient: publicClient ?? viemClients[chainId],
        walletClient: signer,
    })
    return {
        ...c,
        account: signer?.account,
        chain: signer?.chain,
    }
}

export const getBep20Contract = (address: Address, signer?: WalletClient) => {
    return getContract({ abi: erc20ABI, address, signer })
}

export const getErc721Contract = (address: Address, walletClient?: WalletClient) => {
    return getContract({
        abi: erc721ABI,
        address,
        signer: walletClient,
    })
}
export const getLpContract = (address: Address, chainId?: number, signer?: WalletClient) => {
    return getContract({ abi: lpTokenABI, address, signer, chainId })
}

export const getCakeContract = (chainId?: number) => {
    return getContract({
        abi: erc20ABI,
        address: chainId ? ECP[chainId]?.address : ECP[ChainId.LINEA_MAINNET].address,
        chainId,
    })
}

export const getUnsContract = (address: Address, chainId?: ChainId, publicClient?: PublicClient) => {
    return getContract({
        abi: [
            {
                inputs: [
                    {
                        internalType: 'address',
                        name: 'addr',
                        type: 'address',
                    },
                ],
                name: 'reverseNameOf',
                outputs: [
                    {
                        internalType: 'string',
                        name: 'reverseUri',
                        type: 'string',
                    },
                ],
                stateMutability: 'view',
                type: 'function',
            },
        ] as const,
        chainId,
        address,
        publicClient,
    })
}

export const getAirdropContract = (signer?: WalletClient, chainId?: number) => {
    return getContract({ abi: airdropAbi, address: getAirdropAddress(chainId), chainId, signer })
}

export const getXECPContract = (signer?: WalletClient, chainId?: number) => {
    return getContract({ abi: xECPAbi, address: getXECPAddress(chainId), chainId, signer })
}
export const getFarmingV2Contract = (signer?: WalletClient, chainId?: number) => {
    return getContract({ abi: farmingV2Abi, address: getFarmingV2Address(chainId), chainId, signer })
}
export const getFarmingV3Contract = (signer?: WalletClient, chainId?: number) => {
    return getContract({ abi: farmingV3Abi, address: getFarmingV3Address(chainId), chainId, signer })
}
