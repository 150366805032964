// import { ChainId } from '@echodex/sdk'
import { getECPPriceFromSubgraph } from '@echodex/farms/src/fetchFarmsV3'
import { BIG_ZERO } from '@echodex/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { useEffect, useState } from 'react'

export const useCakePriceAsBN = () => {
    const [price, setPrice] = useState(BIG_ZERO)
    useEffect(() => {
        getECPPriceFromSubgraph().then((data) => {
            setPrice(new BigNumber(data))
        })
    }, [])

    return price
}

export const usePriceCakeUSD = (): BigNumber => {
    return useCakePriceAsBN()
}
