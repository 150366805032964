export const airdropAbi = [
    {
        inputs: [],
        name: 'checkIn',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                name: '',
                type: 'address',
            },
            {
                name: '',
                type: 'uint32',
            },
        ],
        name: 'checkedIn',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
]
