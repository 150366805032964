import {
    MenuItemsType,
    DropdownMenuItemType,
    SwapIcon,
    SwapFillIcon,
    EarnIcon,
    MoreIcon,
    DropdownMenuItems,
    BridgeIcon,
    XECPIcon,
    SentIcon,
} from '@echodex/uikit'
import { ContextApi } from '@echodex/localization'
import { CHAIN_IDS } from 'utils/wagmi'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
        {
            label: t('Swap'),
            icon: SwapIcon,
            fillIcon: SwapFillIcon,
            href: '/swap',
            showItemsOnMobile: false,
            supportChainIds: CHAIN_IDS,
        },
        {
            label: t('Liquidity'),
            icon: SwapIcon,
            fillIcon: SwapFillIcon,
            href: '/liquidity',
            items: [
                {
                    label: t('My Liquidity'),
                    href: '/liquidity',
                },
                {
                    label: t('All Pools'),
                    href: '/liquidityPools',
                },
            ].map((item) => addMenuItemSupported(item, chainId)),
            supportChainIds: CHAIN_IDS,
        },
        {
            label: t('Farms'),
            href: '/farming',
            icon: EarnIcon,
            image: '/images/decorations/pe2.png',
            showItemsOnMobile: false,
            supportChainIds: CHAIN_IDS,
        },
        {
            label: t('xECP'),
            href: '/convert-or-redeem',
            icon: XECPIcon,
            image: '/images/decorations/pe2.png',
            showItemsOnMobile: false,
            supportChainIds: CHAIN_IDS,
        },
        {
            label: t('Airdrop'),
            href: '/airdrop',
            icon: SentIcon,
            image: '/images/decorations/pe2.png',
            showItemsOnMobile: false,
            supportChainIds: CHAIN_IDS,
        },
        {
            label: t('Bridge'),
            href: '  ',
            icon: BridgeIcon,
            items: [
                {
                    label: t('Rhino.fi'),
                    href: 'https://app.rhino.fi/bridge?token=ETH',
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                },
                {
                    label: t('Linea Bridge'),
                    href: 'https://bridge.linea.build/',
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                },
                {
                    label: t('Hop Exchange'),
                    href: 'https://goerli.hop.exchange/#/send?token=ETH&sourceNetwork=ethereum&destNetwork=linea',
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                },
                {
                    label: t('Orbiter Finance'),
                    href: 'https://www.orbiter.finance/?source=Ethereum&dest=Arbitrum',
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                },
                {
                    label: t('Symbiosis'),
                    href: 'https://app.symbiosis.finance/swap?chainOut=Linea&tokenOut=ETH',
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                },
                {
                    label: t('DLN'),
                    href: 'https://app.dln.trade/',
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                },
            ].map((item) => addMenuItemSupported(item, chainId)),
            index: 6,
        },
        {
            label: '',
            href: '   ',
            icon: MoreIcon,
            items: [
                {
                    label: t('Docs'),
                    href: 'https://docs.echodex.io',
                    type: DropdownMenuItemType.EXTERNAL_LINK,
                },
                {
                    label: t('DApps'),
                    href: '/dapps',
                },
            ].map((item) => addMenuItemSupported(item, chainId)),
            index: 7,
        },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
