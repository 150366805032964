import { ChainId } from '@echodex/sdk'

export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const V3_BSC_INFO_CLIENT = `https://graph-query.linea.build/subgraphs/name/echodex/exchange-v3-2`
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'
export const BIT_QUERY = 'https://graphql.bitquery.io'
export const ACCESS_RISK_API = '/api/risk'
export const CELER_API = 'https://api.celerscan.com/scan'

export const INFO_CLIENT_WITH_CHAIN = {
    [ChainId.LINEA_TESTNET]: 'https://graph.echodex.io/subgraphs/name/echodex/exchange-v3',
    [ChainId.LINEA_MAINNET]: 'https://graph-query.linea.build/subgraphs/name/echodex/exchange-v3-2',
}
export const ASSET_CDN = 'https://assets.pancakeswap.finance'
export const INFO_CLIENT = 'https://graph.echodex.io/subgraphs/name/echodex/exchange-v3'
export const V3_SUBGRAPH_URLS = {
    [ChainId.LINEA_TESTNET]: 'https://graph.echodex.io/subgraphs/name/echodex/exchange-v3',
    [ChainId.LINEA_MAINNET]: 'https://graph-query.linea.build/subgraphs/name/echodex/exchange-v3-2',
} satisfies Record<ChainId, string | null>

export const V3_SUBGRAPH_URLS_BACKUP = {
    [ChainId.LINEA_TESTNET]: 'https://graph.echodex.io/subgraphs/name/echodex/exchange-v3',
    [ChainId.LINEA_MAINNET]: 'https://graph-mainnet.echodex.io/subgraphs/name/echodex/exchange-v3',
} satisfies Record<ChainId, string | null>

export const V2_SUBGRAPH_URLS = {
    [ChainId.LINEA_TESTNET]: 'https://graph.echodex.io/subgraphs/name/echodex/core',
    [ChainId.LINEA_MAINNET]: 'https://graph-query.linea.build/subgraphs/name/echodex/core',
} satisfies Record<ChainId, string | null>

export const V2_SUBGRAPH_URLS_BACKUP = {
    [ChainId.LINEA_TESTNET]: 'https://graph.echodex.io/subgraphs/name/echodex/core',
    [ChainId.LINEA_MAINNET]: 'https://graph-mainnet.echodex.io/subgraphs/name/echodex/core',
} satisfies Record<ChainId, string | null>

export const MAIN_API = {
    [ChainId.LINEA_TESTNET]: 'https://api-test.echodex.io',
    [ChainId.LINEA_MAINNET]: 'https://api.echodex.io',
} satisfies Record<ChainId, string | null>

export const QUOTING_API = `${process.env.NEXT_PUBLIC_QUOTING_API}/v0/quote`
export const FARMS_API = 'https://farm-api.echodex.io'
