import { languageList, useTranslation } from '@echodex/localization'
import { footerLinks, Menu as UikitMenu, NextLinkFromReactRouter } from '@echodex/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { usePhishingBanner } from '@echodex/utils/user'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const LinkComponent = (linkProps) => {
    return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
}

const Menu = (props) => {
    const { chainId } = useActiveChainId()
    const { isDark, setTheme } = useTheme()
    const { currentLanguage, setLanguage, t } = useTranslation()
    const { pathname } = useRouter()
    const [showPhishingWarningBanner] = usePhishingBanner()

    const menuItems = useMenuItems()

    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

    const toggleTheme = useMemo(() => {
        return () => setTheme(isDark ? 'light' : 'dark')
    }, [setTheme, isDark])

    const getFooterLinks = useMemo(() => {
        return footerLinks(t)
    }, [t])

    return (
        <>
            <UikitMenu
                linkComponent={LinkComponent}
                rightSide={
                    <>
                        <NetworkSwitcher />
                        <UserMenu />
                    </>
                }
                chainId={chainId}
                banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
                isDark={isDark}
                toggleTheme={toggleTheme}
                currentLang={currentLanguage.code}
                langs={languageList}
                setLang={setLanguage}
                links={menuItems}
                subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
                footerLinks={getFooterLinks}
                activeItem={activeMenuItem?.href}
                activeSubItem={activeSubMenuItem?.href}
                {...props}
            />
        </>
    )
}

export default Menu
