import { lineaTestnetTokens } from '@echodex/tokens'
import { FeeAmount } from '@echodex/v3-sdk'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
    {
        pid: 1,
        token0: lineaTestnetTokens.weth,
        token1: lineaTestnetTokens.ecp,
        lpAddress: '0x5971071F7AA686E0682d19d7D4132f9C2fB2f548',
        feeAmount: FeeAmount.HIGH,
    },
    {
        pid: 2,
        token0: lineaTestnetTokens.weth,
        token1: lineaTestnetTokens.usdt,
        lpAddress: '0xe6d05208da9e02E34e31fFFd7eaAB355Da9AB4ED',
        feeAmount: FeeAmount.MEDIUM,
    },
])
