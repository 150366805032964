import { createGlobalStyle } from 'styled-components'
import { EchoDEXTheme } from '@echodex/uikit'

declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends EchoDEXTheme {}
}

const GlobalStyle = createGlobalStyle`
  :root {
    --pri: #63d8a0;
    --gray: #141414;
    --gray1: #808287;
    --white: #fff;
    --black: #0f0f0f;
    --yellow: #fbbf24;
  };
  * {
    font-family: 'Inter', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }
`

export default GlobalStyle
