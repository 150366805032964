import { DEPLOYER_ADDRESSES } from '@echodex/v3-sdk'
import { V3_QUOTER_ADDRESSES } from '@echodex/smart-router/evm'
import { ChainId } from '@echodex/sdk'
import { xECP } from '@echodex/tokens'

export default {
    multiCall: {
        [ChainId.LINEA_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
        [ChainId.LINEA_MAINNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
    nftPositionManager: {
        [ChainId.LINEA_TESTNET]: '0xfedC9DCe80F5A772530c83B6437D8C9ace8Bc419',
        [ChainId.LINEA_MAINNET]: '0x685ceA02C4a8bA4d6279E882bDD90129C1267Cd0',
    },
    v3PoolDeployer: DEPLOYER_ADDRESSES,
    quoter: V3_QUOTER_ADDRESSES,
    airdrop: {
        [ChainId.LINEA_TESTNET]: '0xB05cFe2E2E13707124a357C3a492d1c93584BBde',
        [ChainId.LINEA_MAINNET]: '0xCCE9d3f392c135Dc038b147ca73ec496f7f89D93',
    },
    xECP: {
        [ChainId.LINEA_TESTNET]: xECP[ChainId.LINEA_TESTNET].address,
        [ChainId.LINEA_MAINNET]: xECP[ChainId.LINEA_MAINNET].address,
    },
    farmingV2: {
        [ChainId.LINEA_TESTNET]: '0x93e2d55d8ca01de73AC8142041320CcA34599680',
        [ChainId.LINEA_MAINNET]: '0x00DFEB5761F17aE83E9D3249d23F09eAb355f1F9',
    },
    farmingV3: {
        [ChainId.LINEA_TESTNET]: '0x7959C80d824Ba4ec0F5a6194c041512e3Aa1C0a1',
        [ChainId.LINEA_MAINNET]: '0x2a284Fd69040f784C64CBd2869e3dA6F6d7f24D4',
    },
} as const satisfies Record<string, Record<number, `0x${string}`>>
