import { ChainId, Percent, Token } from '@echodex/sdk'
import { lineaTestnetTokens, lineaTokens } from '@echodex/tokens'
import { ChainTokenList } from './types'

export {
    ADDITIONAL_BASES,
    V2_ROUTER_ADDRESS,
    BASES_TO_CHECK_TRADES_AGAINST,
    CUSTOM_BASES,
} from '@echodex/smart-router/evm'

export const CHAIN_REFRESH_TIME = {
    [ChainId.LINEA_TESTNET]: 12_000,
    [ChainId.LINEA_MAINNET]: 12_000,
} as const satisfies Record<ChainId, number>

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
    [ChainId.LINEA_TESTNET]: [lineaTestnetTokens.usdc, lineaTestnetTokens.weth],
    [ChainId.LINEA_MAINNET]: [lineaTokens.ecp, lineaTokens.usdc, lineaTokens.usdt, lineaTokens.busd],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
    [ChainId.LINEA_TESTNET]: [lineaTestnetTokens.usdc, lineaTestnetTokens.weth],
    [ChainId.LINEA_MAINNET]: [lineaTokens.busd, lineaTokens.weth],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
    [ChainId.LINEA_TESTNET]: [[lineaTestnetTokens.usdc, lineaTestnetTokens.weth]],
    [ChainId.LINEA_MAINNET]: [
        [lineaTokens.ecp, lineaTokens.weth],
        [lineaTokens.busd, lineaTokens.weth],
    ],
}

export const BIG_INT_ZERO = 0n
export const BIG_INT_TEN = 10n

// one basis point
export const BIPS_BASE = 10000n
export const ONE_BIPS = new Percent(1n, BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(100n, BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(300n, BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(500n, BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(1000n, BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(1500n, BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: bigint = BIG_INT_TEN ** 15n // .001 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(50n, BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(25n, BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = 'ETH'
// xECP
export const DEFAULT_OUTPUT_CURRENCY = lineaTokens.ecp.address

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'echodex'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = 500000n

export const EXCHANGE_PAGE_PATHS = ['/swap', 'liquidity', '/add', '/find', '/remove']
