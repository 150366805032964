import { ChainId } from '@echodex/sdk'
import { ComputedFarmConfigV3 } from '../../src/types'
import { FarmV3SupportedChainId } from '../../src'
import { farmsV3 as farm59140 } from '../59140'
import { farmsV3 as farm59144 } from '../59144'

export const farmsV3ConfigChainMap: Record<FarmV3SupportedChainId, ComputedFarmConfigV3[]> = {
    [ChainId.LINEA_TESTNET]: farm59140,
    [ChainId.LINEA_MAINNET]: farm59144,
}
