import { lineaTokens } from '@echodex/tokens'
import { FeeAmount } from '@echodex/v3-sdk'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
    {
        pid: 1,
        token0: lineaTokens.ecp,
        token1: lineaTokens.weth,
        lpAddress: '0xEbc78d2b3C7982E9d4e4Bf6294E81B2cd9e0778b',
        feeAmount: FeeAmount.MEDIUM,
    },
    {
        pid: 2,
        token0: lineaTokens.usdc,
        token1: lineaTokens.weth,
        lpAddress: '0xE4F5Dc6cAb4B23e124d3a73a2CfEE32DC070F72d',
        feeAmount: FeeAmount.MEDIUM,
    },
    {
        pid: 3,
        token0: lineaTokens.busd,
        token1: lineaTokens.weth,
        lpAddress: '0x433f9C64fafB532c3B71b32fFaF6A5796e58Db5D',
        feeAmount: FeeAmount.MEDIUM,
    },
    {
        pid: 4,
        token0: lineaTokens.usdc,
        token1: lineaTokens.usdt,
        lpAddress: '0x92912a1DFBAbD89219F79b961B34053260Add7c2',
        feeAmount: FeeAmount.LOWEST,
    },
])
