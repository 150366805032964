import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
    titleTemplate: '%s | Echodex',
    defaultTitle: 'EchoDex | Linea native Decentralized Exchange',
    description: 'EchoDEX is the first generation DEX on LineaBuild using breakthrough technology in ZK-EVM',
    twitter: {
        cardType: 'summary_large_image',
        handle: '@EchoDEX',
        site: '@EchoDEX',
    },
    openGraph: {
        title: ' EchoDEXSwap - Linea native Decentralized Exchange',
        description: 'EchoDEX is the first generation DEX on LineaBuild using breakthrough technology in ZK-EVM',
    },
}
