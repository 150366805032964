import { FAST_INTERVAL, SLOW_INTERVAL } from 'config/constants'
// eslint-disable-next-line camelcase
import useSWR, { useSWRConfig, unstable_serialize } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { usePublicClient } from 'wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { viemClients } from 'utils/viem'

const REFRESH_BLOCK_INTERVAL = 6000

export const usePollBlockNumber = () => {
    const { cache, mutate } = useSWRConfig()
    const { chainId } = useActiveChainId()

    const { data } = useSWR(
        chainId && ['blockNumberFetcher', chainId],
        async () => {
            const provider = viemClients[chainId as keyof typeof viemClients]
            const blockNumberBigInt = await provider.getBlockNumber()
            const blockNumber = Number(blockNumberBigInt)
            mutate(['blockNumber', chainId], blockNumber)
            if (!cache.get(unstable_serialize(['initialBlockNumber', chainId]))?.data) {
                mutate(['initialBlockNumber', chainId], blockNumber)
            }
            if (!cache.get(unstable_serialize(['initialBlockTimestamp', chainId]))?.data) {
                const block = await provider.getBlock({ blockNumber: blockNumberBigInt })
                mutate(['initialBlockTimestamp', chainId], Number(block.timestamp))
            }
            return blockNumber
        },
        {
            refreshInterval: REFRESH_BLOCK_INTERVAL,
        },
    )

    useSWR(
        chainId && [FAST_INTERVAL, 'blockNumber', chainId],
        async () => {
            return data
        },
        {
            refreshInterval: FAST_INTERVAL,
        },
    )

    useSWR(
        chainId && [SLOW_INTERVAL, 'blockNumber', chainId],
        async () => {
            return data
        },
        {
            refreshInterval: SLOW_INTERVAL,
        },
    )
}

export const useCurrentBlock = (): number => {
    const { chainId } = useActiveChainId()
    const { data: currentBlock = 0 } = useSWRImmutable(['blockNumber', chainId])
    return Number(currentBlock)
}

export const useChainCurrentBlock = (chainId: number): number => {
    const { chainId: activeChainId } = useActiveChainId()
    const provider = usePublicClient({ chainId })
    const { data: currentBlock = 0 } = useSWR(
        chainId ? (activeChainId === chainId ? ['blockNumber', chainId] : ['chainBlockNumber', chainId]) : null,
        activeChainId !== chainId
            ? async () => {
                  const blockNumber = await provider.getBlockNumber()
                  return Number(blockNumber)
              }
            : undefined,
        activeChainId !== chainId
            ? {
                  refreshInterval: REFRESH_BLOCK_INTERVAL,
              }
            : undefined,
    )
    return currentBlock
}

export const useInitialBlock = (): number => {
    const { chainId } = useActiveChainId()
    const { data: initialBlock = 0 } = useSWRImmutable(['initialBlockNumber', chainId])
    return Number(initialBlock)
}

export const useInitialBlockTimestamp = (): number => {
    const { chainId } = useActiveChainId()
    const { data: initialBlockTimestamp = 0 } = useSWRImmutable(['initialBlockTimestamp', chainId])
    return Number(initialBlockTimestamp)
}
