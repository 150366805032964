import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, DiscordIcon, DocsIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About",
    items: [
      {
        label: "Contact",
        href: "https://docs.echodex.io/contact-us",
      },
      {
        label: "Blog",
        href: "https://blog.echodex.io/",
      },
      {
        label: "Community",
        href: "https://docs.echodex.io/contact-us/telegram",
      },
      {
        label: "xECP",
        href: "https://docs.echodex.io/tokenomics/cake",
      },
      {
        label: "—",
      },
      {
        label: "Online Store",
        href: "https://echodex.creator-spring.com/",
        isHighlighted: true,
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://docs.echodex.io/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.echodex.io/help/troubleshooting",
      },
      {
        label: "Guides",
        href: "https://docs.echodex.io/get-started",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/echodex",
      },
      {
        label: "Documentation",
        href: "https://docs.echodex.io",
      },
      {
        label: "Bug Bounty",
        href: "https://app.gitbook.com/@echodex-1/s/echodex/code/bug-bounty",
      },
      {
        label: "Audits",
        href: "https://docs.echodex.io/help/faq#is-echodex-safe-has-echodex-been-audited",
      },
      {
        label: "Careers",
        href: "https://docs.echodex.io/hiring/become-a-chef",
      },
    ],
  },
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/Echo_DEX",
  },
  // {
  //   label: "Telegram",
  //   icon: TelegramIcon,
  //   items: [
  //     {
  //       label: "English",
  //       href: "https://t.me/echodex",
  //     },
  //     {
  //       label: "Bahasa Indonesia",
  //       href: "https://t.me/echodexIndonesia",
  //     },
  //     {
  //       label: "中文",
  //       href: "https://t.me/echodex_CN",
  //     },
  //     {
  //       label: "Tiếng Việt",
  //       href: "https://t.me/EchoDEXSwapVN",
  //     },
  //     {
  //       label: "Italiano",
  //       href: "https://t.me/echodex_Ita",
  //     },
  //     {
  //       label: "русский",
  //       href: "https://t.me/echodex_ru",
  //     },
  //     {
  //       label: "Türkiye",
  //       href: "https://t.me/echodexturkiye",
  //     },
  //     {
  //       label: "Português",
  //       href: "https://t.me/echodexPortuguese",
  //     },
  //     {
  //       label: "Español",
  //       href: "https://t.me/echodexES",
  //     },
  //     {
  //       label: "日本語",
  //       href: "https://t.me/echodexJP",
  //     },
  //     {
  //       label: "Français",
  //       href: "https://t.me/echodexFR",
  //     },
  //     {
  //       label: "Deutsch",
  //       href: "https://t.me/echodex_DE",
  //     },
  //     {
  //       label: "Filipino",
  //       href: "https://t.me/echodex_PH",
  //     },
  //     {
  //       label: "ქართული ენა",
  //       href: "https://t.me/echodexGeorgia",
  //     },
  //     {
  //       label: "हिन्दी",
  //       href: "https://t.me/echodex_INDIA",
  //     },
  //     {
  //       label: "Announcements",
  //       href: "https://t.me/EchoDEXSwapAnn",
  //     },
  //   ],
  // },
  // {
  //   label: "Reddit",
  //   icon: RedditIcon,
  //   href: "https://reddit.com/r/echodex",
  // },
  // {
  //   label: "Instagram",
  //   icon: InstagramIcon,
  //   href: "https://instagram.com/echodex_official",
  // },
  // {
  //   label: "Github",
  //   icon: GithubIcon,
  //   href: "https://github.com/echodex/",
  // },
  {
    label: "Discord",
    icon: DiscordIcon,
    href: "https://discord.com/invite/mtSmqAAjkw",
  },
  {
    label: "Docs",
    icon: DocsIcon,
    href: "https://docs.echodex.io/",
  },
  // {
  //   label: "Youtube",
  //   icon: YoutubeIcon,
  //   href: "https://www.youtube.com/@echodex_official",
  // },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
