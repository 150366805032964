import { Abi, Address } from 'viem'
import { erc20ABI, useWalletClient } from 'wagmi'

import { useActiveChainId } from 'hooks/useActiveChainId'

import addresses from 'config/constants/contracts'
import { useMemo } from 'react'
import { getMulticallAddress } from 'utils/addressHelpers'
import {
    getAirdropContract,
    getContract,
    getFarmingV2Contract,
    getFarmingV3Contract,
    getXECPContract,
} from 'utils/contractHelpers'

import { ChainId, WNATIVE, echodexPairV2ABI } from '@echodex/sdk'
import { nonfungiblePositionManagerABI } from '@echodex/v3-sdk'
import { multicallABI } from 'config/abi/Multicall'
import { erc20Bytes32ABI } from 'config/abi/erc20_bytes32'
import { erc721CollectionABI } from 'config/abi/erc721collection'
import { wethABI } from 'config/abi/weth'
import { xECP } from '@echodex/tokens'
/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: Address) => {
    return useContract(address, erc20ABI)
}

export const useXECP = () => {
    const { chainId } = useActiveChainId()
    return useContract(xECP[chainId].address ?? xECP[ChainId.LINEA_MAINNET].address, erc20ABI)
}

export const useErc721CollectionContract = (collectionAddress: Address) => {
    return useContract(collectionAddress, erc721CollectionABI)
}

// returns null on errors
export function useContract<TAbi extends Abi>(
    addressOrAddressMap?: Address | { [chainId: number]: Address },
    abi?: TAbi,
) {
    const { chainId } = useActiveChainId()
    const { data: walletClient } = useWalletClient()

    return useMemo(() => {
        if (!addressOrAddressMap || !abi || !chainId) return null
        let address: Address | undefined
        if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
        else address = addressOrAddressMap[chainId]
        if (!address) return null
        try {
            return getContract({
                abi,
                address,
                chainId,
                signer: walletClient,
            })
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [addressOrAddressMap, abi, chainId, walletClient])
}

export function useTokenContract(tokenAddress?: Address) {
    return useContract(tokenAddress, erc20ABI)
}

export function useWNativeContract() {
    const { chainId } = useActiveChainId()
    return useContract(chainId ? WNATIVE[chainId]?.address : undefined, wethABI)
}

export function useBytes32TokenContract(tokenAddress?: Address) {
    return useContract(tokenAddress, erc20Bytes32ABI)
}

export function usePairContract(pairAddress?: Address) {
    return useContract(pairAddress, echodexPairV2ABI)
}

export function useMulticallContract() {
    const { chainId } = useActiveChainId()
    return useContract(getMulticallAddress(chainId), multicallABI)
}

export function useV3NFTPositionManagerContract() {
    return useContract(addresses.nftPositionManager, nonfungiblePositionManagerABI)
}

export function useAirdropContract() {
    const { chainId } = useActiveChainId()
    const { data: signer } = useWalletClient()
    return useMemo(() => getAirdropContract(signer, chainId), [signer, chainId])
}

export function useXECPContract() {
    const { chainId } = useActiveChainId()
    const { data: signer } = useWalletClient()
    return useMemo(() => getXECPContract(signer, chainId), [signer, chainId])
}
export function useFarmingV2Contract() {
    const { chainId } = useActiveChainId()
    const { data: signer } = useWalletClient()
    return useMemo(() => getFarmingV2Contract(signer, chainId), [signer, chainId])
}
export function useFarmingV3Contract() {
    const { chainId } = useActiveChainId()
    const { data: signer } = useWalletClient()
    return useMemo(() => getFarmingV3Contract(signer, chainId), [signer, chainId])
}
