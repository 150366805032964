import { parseEther } from 'viem'
import { SerializedFarmsState } from '@echodex/farms'

export enum GAS_PRICE {
    default = '3',
    fast = '4',
    instant = '5',
    testnet = '10',
}

export const GAS_PRICE_GWEI = {
    rpcDefault: 'rpcDefault',
    default: parseEther(GAS_PRICE.default, 'gwei').toString(),
    fast: parseEther(GAS_PRICE.fast, 'gwei').toString(),
    instant: parseEther(GAS_PRICE.instant, 'gwei').toString(),
    testnet: parseEther(GAS_PRICE.testnet, 'gwei').toString(),
}

export interface BigNumberToJson {
    type: 'BigNumber'
    hex: string
}

export type SerializedBigNumber = string

export interface Market {
    paused: boolean
    epoch: number
}

export enum HistoryFilter {
    ALL = 'all',
    COLLECTED = 'collected',
    UNCOLLECTED = 'uncollected',
}

export interface RoundData {
    [key: string]: ReduxNodeRound
}

export interface ReduxNodeRound {
    epoch: number
    startTimestamp: number | null
    lockTimestamp: number | null
    closeTimestamp: number | null
    lockPrice: string | null
    closePrice: string | null
    totalAmount: string
    bullAmount: string
    bearAmount: string
    rewardBaseCalAmount: string
    rewardAmount: string
    oracleCalled: boolean
    lockOracleId: string
    closeOracleId: string
}

export interface NodeRound {
    epoch: number
    startTimestamp: number | null
    lockTimestamp: number | null
    closeTimestamp: number | null
    lockPrice: bigint | null
    closePrice: bigint | null
    totalAmount: bigint
    bullAmount: bigint
    bearAmount: bigint
    rewardBaseCalAmount: bigint
    rewardAmount: bigint
    oracleCalled: boolean
    closeOracleId: string
    lockOracleId: string
}

export type LeaderboardFilterTimePeriod = '1d' | '7d' | '1m' | 'all'

export interface LeaderboardFilter {
    address?: string
    orderBy?: string
    timePeriod?: LeaderboardFilterTimePeriod
}

// Global state
export interface State {
    farms: SerializedFarmsState
    farmsV1: SerializedFarmsState
}
