import { ChainId, ERC20Token } from '@echodex/sdk'
import { lineaTestnetTokens, lineaTokens } from '@echodex/tokens'
import type { CommonPrice } from '../../src/fetchFarmsV3'
import type { FarmV3SupportedChainId } from '../../src'

export const ECP_ETH_PAIR_MAINNET = '0x7c3892BE12ADF04DEAEC3909b2d849F341f61dBd'

export type PriceHelper = {
    chain: string
    list: ERC20Token[]
}

export const CHAIN_ID_TO_CHAIN_NAME = {
    [ChainId.LINEA_TESTNET]: 'linea_testnet',
    [ChainId.LINEA_MAINNET]: 'linea',
} satisfies Record<FarmV3SupportedChainId, string>

export const priceHelperTokens = {
    [ChainId.LINEA_TESTNET]: {
        chain: 'linea_testnet',
        list: [lineaTestnetTokens.weth, lineaTestnetTokens.usdt],
    },
    [ChainId.LINEA_MAINNET]: {
        chain: 'linea',
        list: [lineaTokens.weth, lineaTokens.busd],
    },
} satisfies Record<number, PriceHelper>

// for testing purposes
export const DEFAULT_COMMON_PRICE: Record<FarmV3SupportedChainId, CommonPrice> = {
    [ChainId.LINEA_TESTNET]: {},
    [ChainId.LINEA_MAINNET]: {},
}
