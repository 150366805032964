import { PublicClient } from 'viem'
import { ChainId } from '@echodex/sdk'
import BigNumber from 'bignumber.js'
import {
    FarmV3SupportedChainId,
    masterChefV3Addresses,
    supportedChainIdV2,
    supportedChainIdV3,
    bCakeSupportedChainId,
} from './const'
import {
    farmV3FetchFarms,
    fetchMasterChefV3Data,
    fetchCommonTokenUSDValue,
    fetchTokenUSDValues,
    CommonPrice,
    LPTvl,
    getCakeApr,
} from './fetchFarmsV3'
import { ComputedFarmConfigV3, FarmV3DataWithPrice } from './types'

export { type FarmV3SupportedChainId, supportedChainIdV3, bCakeSupportedChainId, supportedChainIdV2 }

export function createFarmFetcherV3(provider: ({ chainId }: { chainId: number }) => PublicClient) {
    const fetchFarms = async ({
        farms,
        chainId,
        commonPrice,
    }: {
        farms: ComputedFarmConfigV3[]
        chainId: FarmV3SupportedChainId
        commonPrice: CommonPrice
    }) => {
        const masterChefAddress = masterChefV3Addresses[chainId]
        if (!masterChefAddress) {
            throw new Error('Unsupported chain')
        }

        try {
            const { poolLength, totalAllocPoint, latestPeriodCakePerSecond } = await fetchMasterChefV3Data({
                provider,
                masterChefAddress,
                chainId,
            })

            const cakePerSecond = new BigNumber(latestPeriodCakePerSecond.toString()).div(1e18).div(1e12).toString()

            const farmsWithPrice = await farmV3FetchFarms({
                farms,
                chainId,
                provider,
                masterChefAddress,
                totalAllocPoint,
                commonPrice,
            })

            return {
                poolLength: Number(poolLength),
                farmsWithPrice,
                cakePerSecond,
                totalAllocPoint: totalAllocPoint.toString(),
            }
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getCakeAprAndTVL = (farm: FarmV3DataWithPrice, lpTVL: LPTvl, cakePrice: string, cakePerSecond: string) => {
        const [token0Price, token1Price] = farm.token.sortsBefore(farm.quoteToken)
            ? [farm.tokenPriceBusd, farm.quoteTokenPriceBusd]
            : [farm.quoteTokenPriceBusd, farm.tokenPriceBusd]
        const tvl = new BigNumber(token0Price).times(lpTVL.token0).plus(new BigNumber(token1Price).times(lpTVL.token1))

        const cakeApr = getCakeApr(farm.poolWeight, tvl, cakePrice, cakePerSecond)

        return {
            activeTvlUSD: tvl.toString(),
            activeTvlUSDUpdatedAt: lpTVL.updatedAt,
            cakeApr,
        }
    }

    return {
        fetchFarms,
        getCakeAprAndTVL,
        isChainSupported: (chainId: number): chainId is FarmV3SupportedChainId => supportedChainIdV3.includes(chainId),
        supportedChainId: supportedChainIdV3,
        isTestnet: (chainId: number) => ![ChainId.LINEA_MAINNET].includes(chainId),
    }
}

export * from './apr'
export * from './utils'
export * from './types'
export { FARM_AUCTION_HOSTING_IN_SECONDS } from './const'

export { masterChefV3Addresses, fetchCommonTokenUSDValue, fetchTokenUSDValues }
