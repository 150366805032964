import { ChainId } from '@echodex/sdk'

export const SERVER_NODES = {
    [ChainId.LINEA_TESTNET]: [
        'https://rpc.goerli.linea.build',
        'https://linea-testnet.rpc.thirdweb.com',
        'https://consensys-zkevm-goerli-prealpha.infura.io/v3/93e8a17747e34ec0ac9a554c1b403965',
    ],
    [ChainId.LINEA_MAINNET]: ['https://rpc.linea.build', 'https://linea.rpc.thirdweb.com'],
} satisfies Record<ChainId, readonly string[]>

export const PUBLIC_NODES = {
    [ChainId.LINEA_TESTNET]: [
        'https://rpc.goerli.linea.build',
        'https://linea-testnet.rpc.thirdweb.com',
        'https://consensys-zkevm-goerli-prealpha.infura.io/v3/93e8a17747e34ec0ac9a554c1b403965',
    ],
    [ChainId.LINEA_MAINNET]: ['https://rpc.linea.build', 'https://linea.rpc.thirdweb.com'],
} satisfies Record<ChainId, readonly string[]>
