import '@echodex/ui/css/reset.css'
import '../style/global.scss'
import { ResetCSS, ScrollToTopButtonV2, ToastListener } from '@echodex/uikit'
import BigNumber from 'bignumber.js'
import { NetworkModal } from 'components/NetworkModal'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import TransactionsDetailModal from 'components/TransactionDetailModal'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import useSentryUser from 'hooks/useSentryUser'
import useThemeCookie from 'hooks/useThemeCookie'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { Fragment } from 'react'
import { DefaultSeo } from 'next-seo'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { Blocklist, Updaters } from '..'
import { SEO } from '../../next-seo.config'
import { SentryErrorBoundary } from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
// This config is required for number formatting
BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})

function GlobalHooks() {
    usePollBlockNumber()
    useEagerConnect()
    useUserAgent()
    useAccountEventListener()
    useSentryUser()
    useThemeCookie()
    return null
}

function MPGlobalHooks() {
    usePollBlockNumber()
    useEagerConnectMP()
    useUserAgent()
    useAccountEventListener()
    useSentryUser()
    return null
}

function MyApp(props: AppProps<{ initialReduxState: any }>) {
    const { pageProps, Component } = props
    const store = useStore(pageProps.initialReduxState)

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
                />
                <meta name="description" content="..." />
                <meta name="theme-color" content="#63d8a0" />
                {/* for Facebook */}
                <meta property="og:title" content="EchoDEX - The next gen DEX on LineaBuild, ConsenSys" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="/images/banner-facebook.jpg" />
                <meta
                    property="og:description"
                    content="EchoDEX is the first generation DEX on LineaBuild using breakthrough technology in ZK-EVM"
                />

                {/* for Twitter */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="EchoDEX - The next gen DEX on LineaBuild, ConsenSys" />
                <meta
                    name="twitter:description"
                    content="EchoDEX is the first generation DEX on LineaBuild using breakthrough technology in ZK-EVM"
                />
                <meta name="twitter:image" content="/images/banner-twitter.jpg" />
            </Head>
            <DefaultSeo {...SEO} />
            <Providers store={store}>
                {/* <PageMeta /> */}
                {(Component as NextPageWithLayout).Meta && (
                    // @ts-ignore
                    <Component.Meta {...pageProps} />
                )}
                <Blocklist>
                    {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
                    <ResetCSS />
                    <GlobalStyle />
                    <PersistGate loading={null} persistor={persistor}>
                        <Updaters />
                        <App {...props} />
                    </PersistGate>
                </Blocklist>
            </Providers>
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-5P8CVN7T97" async />
            <Script
                strategy="afterInteractive"
                id="google-tag-new"
                dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', 'G-5P8CVN7T97');
          `,
                }}
            />
        </>
    )
}

type NextPageWithLayout = NextPage & {
    Layout?: React.FC<React.PropsWithChildren<unknown>>
    /** render component without all layouts */
    pure?: true
    /** is mini program */
    mp?: boolean
    chains?: number[]
    isShowScrollToTopButton?: true
    /**
     * Meta component for page, hacky solution for static build page to avoid `PersistGate` which blocks the page from rendering
     */
    Meta?: React.FC<React.PropsWithChildren<unknown>>
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    if (Component.pure) {
        return <Component {...pageProps} />
    }

    // Use the layout defined at the page level, if available
    const Layout = Component.Layout || Fragment
    const ShowMenu = Component.mp ? Fragment : Menu
    const isShowScrollToTopButton = Component.isShowScrollToTopButton || true

    return (
        <ProductionErrorBoundary>
            <ShowMenu>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </ShowMenu>
            <ToastListener />
            <FixedSubgraphHealthIndicator />
            <NetworkModal pageSupportedChains={Component.chains} />
            <TransactionsDetailModal />
            {isShowScrollToTopButton && <ScrollToTopButtonV2 />}
        </ProductionErrorBoundary>
    )
}

export default MyApp
